import { Link } from 'react-router-dom';
import { LinksArray } from '../Header/Links';
import style from './Styles.module.scss';
import logoSvg from './assets/logo.svg';

const Footer = () => {
  const linkList = () => {
    return LinksArray.map((item, index) => (
      <Link to={item.to} key={index} className={style.footerTop__linksItem}>
        {item.name}
      </Link>
    ));
  };

  return (
    <footer className={style.footer}>
      <div className='container'>
        <div className={style.footerTop}>
          <div className={style.footerTop__logo}>
            <img src={logoSvg} alt='' />
          </div>
          <div className={style.footerTop__links}>{linkList()}</div>
        </div>
        <div className={style.footerBody}>
          <div className={style.footerBody__info}>
            <div className={style.footerBody__infoTitle}>contact us</div>
            <a
              href='mailto:info@algoquant.io'
              className={style.footerBody__infoLink}
            >
              info@algoquant.io
            </a>
          </div>
          <div className={style.footerBody__descr}>
            Please note that AlgoQuant, LTD is a proprietary trading firm and is
            not soliciting customers or customer relationships by means of this
            website or otherwise. Furthermore, AlgoQuant, LTD is not offering
            any securities or other financial products for sale nor is it
            soliciting any transactions or investment through this website. The
            information on this website is not intended to constitute
            investment, legal or tax advice and may not be relied upon as such
            by any person. AlgoQuant, LTD maintains this website for
            informational purposes only and undertakes no obligation regarding
            the information contained herein
          </div>
        </div>
        <div className={style.footerLinks}>
          <Link to={'privacy'} className={style.footerLinks__item}>
            Privacy and cookies policy
          </Link>
          <div className={style.footerLinks__item}>2023 Algoquant LTD</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
