import { Fade } from 'react-reveal';
import Button from '../../../../components/Button/Index';
import style from './Style.module.scss';
import portalPart1 from './assets/part1.mp4';
import portalPart2 from './assets/part2.mp4';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(ScrollTrigger);

const TeamSection = () => {
  const scopeRef = useRef(null);
  const videoRef = useRef(null);
  const video2Ref = useRef(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isNextVideo, setIsNextVideo] = useState(false);
  const isMobile = useMediaQuery('(max-width:991px)');

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.create({
        trigger: scopeRef.current,
        start: () => (isMobile ? '-70%' : '-90%'),
        end: 'bottom',
        markers: false,
        onEnter: (self) => {
          if (!isPlayed) {
            videoRef.current.play();
            setIsPlayed(true);

            return setTimeout(() => {
              setIsNextVideo(true);
              video2Ref.current.play();
            }, 850);
          }
        },
      });
    }, scopeRef);
    return () => ctx.revert();
  }, [isPlayed, isMobile]);

  return (
    <section ref={scopeRef} className={style.team}>
      <div className={style.team__shadowVideo}>
        <video
          ref={videoRef}
          src={portalPart1}
          playsInline
          webkit-playsInline
          muted
          autoPlay
        ></video>
        <video
          ref={video2Ref}
          src={portalPart2}
          playsInline
          webkit-playsInline
          muted
          loop
          autoPlay
        ></video>
      </div>

      <div className='container'>
        <div id='team-body' className={style.teamBody}>
          {!isMobile && (
            <div
              className={`${style.teamBody__portal} ${
                isNextVideo ? style.active : ''
              }`}
            >
              <video
                ref={videoRef}
                src={portalPart1}
                playsInline
                webkit-playsInline
                muted
              ></video>
              <video
                ref={video2Ref}
                src={portalPart2}
                playsInline
                webkit-playsInline
                muted
                loop
              ></video>
            </div>
          )}

          <div className={style.teamBody__title}>
            <Fade up>team</Fade>
          </div>
          {isMobile && (
            <div
              className={`${style.teamBody__portal} ${
                isNextVideo ? style.active : ''
              }`}
            >
              <video
                ref={videoRef}
                src={portalPart1}
                playsInline
                webkit-playsInline
                muted
              ></video>
              <video
                ref={video2Ref}
                src={portalPart2}
                playsInline
                webkit-playsInline
                muted
                loop
              ></video>
            </div>
          )}
          <div className={style.teamBody__text}>
            <Fade up>
              <p>
                Our team consists of talented Quants and developers who
                collaborate to explore most exciting investment opportunities.
                Most of our team members have advanced degrees in Maths,
                Physics, Economics or CS. We work with the most talented people
                in our field to minimize risk, maximize profits and outperform
                our competitors.
              </p>
            </Fade>
            <Fade up>
              <p>
                We value dedication and strive to continually excel in our
                field. Our work culture encourages collaboration, curiosity and
                initiative, as we believe that such an atmosphere encourages
                creative ideas and allows us to achieve high results in our
                field.
              </p>
            </Fade>
            <Fade up>
              <p>
                We support remote work, which allows us to bring together the
                best talent from around the world, creating equal opportunities
                regardless of geographic location. Our team members are located
                across North America, Europe and Asia. Despite our remote setup,
                we recognize the importance of face-to-face interaction, holding
                occasional offline meetings to strengthen team relationships.
              </p>
            </Fade>
          </div>
          <Fade up>
            <Button
              href={'mailto:info@algoquant.io'}
              title={'Contact Us'}
              className={style.teamBody__btn}
            />
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
