import ApproachCard from './Components/Card/Index';
import style from './Styles.module.scss';
import TextContent from './TextContent';
import light2x from '../../../../assets/img/ligh2@2x.png';
import light from '../../../../assets/img/ligh2.png';
import TextAnim from '../../../../components/TextAnim';

const ApproachSection = ({ panelsContainer }) => {
  return (
    <section className={style.approach}>
      <div className={style.approach__light}>
        <img src={light} srcSet={`${light} 1x, ${light2x} 2x`} alt='' />
      </div>
      <div className='container'>
        <div className={style.approach__title}>
          <TextAnim text={TextContent.title} />
        </div>
        <div className={style.approach__text}>
          <TextAnim text={TextContent.text} />
        </div>
        <div
          ref={panelsContainer}
          id='panels-container'
          className={style.approachBody}
        >
          {TextContent.cards.map((item, index) => (
            <ApproachCard
              id='panels'
              className='panel'
              key={index}
              title={item.title}
              descr={item.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ApproachSection;
