import TextAnim from '../../../../components/TextAnim';
import style from './Infrastructure.module.scss';
import TextContent from './TextContent';
import InfrastructureCard from './components/Card/Index';

const InfrastructureSection = () => {
  return (
    <section className={style.infrastructure}>
      <div className='container'>
        <div className={style.infrastructure__title}>
          <TextAnim text={TextContent.title} />
        </div>
        <div className={style.infrastructureBody}>
          {TextContent.cards.map((item, index) => (
            <InfrastructureCard
              key={index}
              title={item.title}
              text={item.text}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InfrastructureSection;
