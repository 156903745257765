import { useEffect, useRef, useState } from 'react';
import style from './Style.module.scss';
import useIntersectionObserver from '@react-hook/intersection-observer';

const TextAnim = ({ text }) => {
  const itemRef = useRef(null);
  const textSpan = text
    .split(' ')
    .map((item, index) => <span key={index}>{item} </span>);

  const { isIntersecting } = useIntersectionObserver(itemRef, {
    threshold: 0.5,
  });
  const [isIntersected, setIsIntersected] = useState(false);
  useEffect(() => {
    if (isIntersecting) {
      setIsIntersected(true);
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersected) {
      itemRef?.current.querySelectorAll('span').forEach((element) => {
        element.style.animationPlayState = 'running';
      });
    }
  }, [isIntersected]);

  return (
    <span ref={itemRef} className={style.text}>
      {textSpan}
    </span>
  );
};

export default TextAnim;
