import TextAnim from '../../../../components/TextAnim';
import style from './Styles.module.scss';
import video from './assets/video.mp4';

const GlobalSection = () => {
  return (
    <section className={style.global}>
      <div className='container'>
        <div className={style.global__title}>
          <TextAnim text={'global presence'} />
        </div>
        <div className={style.global__text}>
          <TextAnim
            text={
              'AlgoQuant is a fully remote team that works seamlessly from different countries, ensuring constant interaction with the markets. Leveraging advanced technical infrastructure, our technical specialists are dedicated to minimizing latency and ensuring a consistently reliable connection to exchanges.'
            }
          />
        </div>
        <div className={style.global__map}>
          <video
            playsInline
            webkit-playsInline
            loop
            autoPlay
            muted
            src={video}
          ></video>
        </div>
      </div>
    </section>
  );
};

export default GlobalSection;
