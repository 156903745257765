import AboutSection from './Modules/About/Index';
import ApproachSection from './Modules/Approach';
import GlobalSection from './Modules/Global/Index';
import InfrastructureSection from './Modules/Infrastructure/Index';
import MainSection from './Modules/Main/Index';
import TeamSection from './Modules/Team/Index';
import style from './Styles.module.scss';
import { useLayoutEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
  const scopeRef = useRef(null);
  const panelsContainer = useRef(null);
  const isMobile = useMediaQuery('(max-width:767px)');

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (isMobile) {
        const panels = gsap.utils.toArray('#panels-container .panel');
        const tween1 = gsap.to(panels, {
          x: -panelsContainer.current.offsetWidth - 20,
          ease: 'none',
          scrollTrigger: {
            trigger: '#panels-container',
            start: '-=390',
            scrub: 1,
            markers: false,
            end: () => '+=' + panelsContainer.current.offsetWidth / 3.2,
          },
        });
      }
    }, scopeRef);
    return () => ctx.revert();
  }, [isMobile]);

  return (
    <>
      <MainSection />
      <div className={style.cubeWrapper} ref={scopeRef}>
        {isMobile ? (
          <div>
            <AboutSection />
            <ApproachSection panelsContainer={panelsContainer} />
            <GlobalSection />
            <TeamSection />
          </div>
        ) : (
          <>
            <AboutSection />
            <ApproachSection />
            <GlobalSection />
            <TeamSection />
          </>
        )}
      </div>
      <InfrastructureSection />
    </>
  );
};

export default HomePage;
