export const LinksArray = [
  {
    name: 'home',
    to: '/',
  },
  // {
  //   name: 'corporate solution',
  //   to: '',
  // },
  // {
  //   name: 'blog',
  //   to: '',
  // },
  {
    name: 'asset managers',
    to: 'asset-managers',
  },
  {
    name: 'career',
    to: 'careers',
  },
  // {
  //   name: 'contact',
  //   to: '',
  // },
];
