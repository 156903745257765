const TextContent = {
  title: 'Our Infrastructure',
  cards: [
    {
      title: 'risk management',
      text: 'Investment success depends on effective risk management. Our robust real-time risk management system allows us to be vigilant at all levels. Our team is constantly evaluating potential vulnerabilities, stress testing our strategies and responding to market events to ensure optimal security and performance. Our approach to risk management, covering both pre-trade and post-trade methods as well as counterparty risks',
    },
    {
      title: 'trading system',
      text: 'Our trading systems are designed for seamless strategy integration backtesting and optimal execution. The technical team takes responsibility for the integration with the trading platforms, and also provides our quantitative specialists with the necessary tools for backtesting and real-time trading, allowing quants to focus on the development of signal modules. The reliability of our trading systems is evidenced by the high volume of orders they have successfully managed over the years.',
    },
    {
      title: 'data',
      text: 'Our investment approach is based on data-driven insights from a wide range of markets and trading platforms. We believe in the power of alternative data, using its potential to unlock uncharted possibilities and insights. We partner with leading industry vendors to purchase numerous datasets that give us a clear edge in our analysis. At the heart of our model development strategy is our commitment to data quality - it must be accurate, clean and easily accessible to our team. This allows our quantitative analysts to quickly connect to diverse datasets, taking our data-driven approach to new heights.',
    },
  ],
};

export default TextContent;
