import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/Index';
import Header from './components/Header/Index';
import Footer from './components/Footer/Index';
// import PrivacyPage from './pages/Privacy';
// import ManagersPage from './pages/Managers';
// import CareersPage from './pages/Careers';

const ManagersPage = React.lazy(() => import('./pages/Managers'));
const CareersPage = React.lazy(() => import('./pages/Careers'));
const PrivacyPage = React.lazy(() => import('./pages/Privacy'));

function App() {
  return (
    <div className='wrapper'>
      <div className='content'>
        <Header />

        <Suspense fallback={<div className=''></div>}>
          <Routes>
            <Route path='*' element={<HomePage />} />
            <Route path='asset-managers' element={<ManagersPage />} />
            <Route path='careers' element={<CareersPage />} />
            <Route path='privacy' element={<PrivacyPage />} />
          </Routes>
        </Suspense>

        <Footer />
      </div>
    </div>
  );
}

export default App;
