const TextContent = {
  title: 'Our approach',
  text: 'Our main focus is to make the investment process into a broad universe of assets smart, easy, and safe. AlgoQuant is a forward-thinking firm that uses advanced technology to generate cutting-edge investment strategies. Algoquant develops its own solutions and works with internal and external teams to achieve optimal results. We are passionate about revolutionizing the way trading works, seeking out new opportunities, and making positive contributions to the world of finance.',
  cards: [
    {
      title: 'Internal allocation',
      text: [
        'At the core of our operations lies the innovative Meta Strategy, an advanced tool that brings together various predictive models into a single robust trading mechanism. The Meta Strategy harnesses insights from unconventional data streams like blockchain and news, as well as price-based models, equipping it with a comprehensive understanding of the market.',
        'Our infrastructure allows us to trade with different frequencies from milliseconds to days and across different markets: spot, futures, options. All trading is fully automated.',
      ],
    },
    {
      title: 'External allocations',
      text: [
        'We engage with external asset managers, leveraging our industry knowledge to rigorously assess their investment approaches. Our evaluation criteria process ensures we only collaborate with the finest in the field. In our relentless pursuit of excellence, we continually explore opportunities with new managers and stay ahead of industry trends.',
      ],
    },
  ],
};

export default TextContent;
