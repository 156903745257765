import { Fade } from 'react-reveal';
import Button from '../../../../../../components/Button/Index';
import style from './Styles.module.scss';

const ApproachCard = ({ id, title, descr, className }) => {
  return (
    <div id={id} className={`${style.card} ${className}`}>
      <span></span>
      <div className={style.card__title}>
        <Fade up>{title}</Fade>
      </div>
      <div className={style.card__descr}>
        {descr.map((item, index) => (
          <Fade up>
            <p key={index}>{item}</p>
          </Fade>
        ))}
      </div>
      <Button
        href={'mailto:info@algoquant.io'}
        className={style.card__btn}
        title={'Contact Us'}
      />
    </div>
  );
};

export default ApproachCard;
