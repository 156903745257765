import { Fade } from 'react-reveal';
import style from './Index.module.scss';

const CardAbout = ({ className, icon, value, text }) => {
  return (
    <div className={`${style.card} ${className}`}>
      <span></span>
      <Fade>
        <div className={style.card__icon}>
          <img src={icon} alt='' />
        </div>
      </Fade>
      <Fade up>
        <div className={style.card__value}>{value}</div>
      </Fade>
      <Fade up>
        <div className={style.card__text}>{text}</div>
      </Fade>
    </div>
  );
};

export default CardAbout;
