import CardAbout from './Components/Card/Index';
import style from './Styles.module.scss';
import img1 from './assets/icon1.svg';
import img2 from './assets/icon2.svg';
import img3 from './assets/icon3.svg';
import img4 from './assets/icon4.svg';
import light from '../../../../assets/img/ligh1.png';
import light2x from '../../../../assets/img/ligh1@2x.png';
import TextAnim from '../../../../components/TextAnim';
import { Fade } from 'react-reveal';
import cube1 from './assets/cube1.png';
import cube1_2x from './assets/cube1@2x.png';
import cube2 from './assets/cube2.png';
import cube2_2x from './assets/cube2@2x.png';
import cube3 from './assets/cube3.png';
import cube3_2x from './assets/cube3@2x.png';
import cube4 from './assets/cube4.png';
import cube4_2x from './assets/cube4@2x.png';

const AboutSection = () => {
  return (
    <section className={style.about}>
      <div className={style.about__light}>
        <img src={light} srcSet={`${light} 1x, ${light2x} 2x`} alt='' />
      </div>
      <div className={`${style.con} container`}>
        <div className={style.about__bg}>
          <div className={style.about__bgItem}>
            <Fade up>
              <img src={cube1} srcSet={`${cube1} 1x, ${cube1_2x} 2x`} alt='' />
            </Fade>
          </div>
          <div className={style.about__bgItem}>
            <Fade up>
              <img src={cube2} srcSet={`${cube2} 1x, ${cube2_2x} 2x`} alt='' />
            </Fade>
          </div>
          <div className={style.about__bgItem}>
            <Fade up>
              <img src={cube3} srcSet={`${cube3} 1x, ${cube3_2x} 2x`} alt='' />
            </Fade>
          </div>
          <div className={style.about__bgItem}>
            <Fade up>
              <img src={cube4} srcSet={`${cube4} 1x, ${cube4_2x} 2x`} alt='' />
            </Fade>
          </div>
        </div>
        <div className={style.about__title}>
          <TextAnim text={'about'} />
        </div>
        <div className={style.about__text}>
          <p>
            <TextAnim
              text={
                'Founded in 2018, AlgoQuant is a company focused on building sophisticated trading solutions.'
              }
            />
          </p>
          <p>
            <TextAnim
              text={
                'Our professional team is bringing significant market experience and state of art trading ideas to the financial markets. Algoquant develops its own products and works with external teams to achieve optimal results. We are inspired by the emergence of new markets technologies. Over the years, we have continued to expand our expertise, adapt to changing market conditions, excel and compete at the highest level with the best teams.'
              }
            />
          </p>
        </div>
        <div className={style.aboutBody}>
          <div className={style.aboutCol}>
            <CardAbout
              className={style.aboutCol__item}
              icon={img1}
              value={5}
              text={'Years on the market'}
            />
            <CardAbout
              className={style.aboutCol__item}
              icon={img2}
              value={'+100TB'}
              text={'Of data, used to create models'}
            />
          </div>
          <div className={style.aboutCol}>
            <CardAbout
              className={style.aboutCol__item}
              icon={img3}
              value={'30+'}
              text={'People in the team'}
            />
            <CardAbout
              className={style.aboutCol__item}
              icon={img4}
              value={'>1B'}
              text={'trades executed through our trading platform'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
