import style from './Styles.module.scss';
import video from './assets/video.mp4';

const MainSection = () => {
  return (
    <section className={style.main}>
      <div className='container'>
        <div className={style.mainBody}>
          <div className={style.mainBody__title}>Algoquant</div>
          <div className={style.mainBody__div}></div>
          <div className={style.mainBody__info}>
            <div className={style.mainBody__infoSubtitle}>
              An innovative digital assets and algorithmic trading firm
            </div>
            <div className={style.mainBody__infoDescr}>
              A PIONEERING FINTECH AND ALGORITHMIC TRADING FIRM
            </div>
          </div>
        </div>
        <div className={style.mainVideo}>
          <video
            playsInline
            webkit-playsInline
            loop
            autoPlay
            muted
            src={video}
          ></video>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
