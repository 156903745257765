import style from './Styles.module.scss';

const Button = ({ className, title, href }) => {
  if (href) {
    return (
      <a href={href} className={`${style.button} ${className}`}>
        {title}
      </a>
    );
  } else {
    return <button className={`${style.button} ${className}`}>{title}</button>;
  }
};

export default Button;
