import { Link, useNavigate } from 'react-router-dom';
import style from './Styles.module.scss';
import logoSvg from './assets/logo.svg';
import { LinksArray } from './Links';
import { useMediaQuery } from 'usehooks-ts';
import { useState } from 'react';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [isBurgerOpen, setBurgerOpen] = useState(false);
  const navigate = useNavigate();

  const navTo = (link) => {
    setBurgerOpen(false);
    navigate(link);
  };

  const linkList = () => {
    return LinksArray.map((item, index) => (
      <Link to={item.to} key={index} className={style.headerBody__linksItem}>
        {item.name}
      </Link>
    ));
  };

  const linkListMobile = () => {
    return LinksArray.map((item, index) => (
      <div
        onClick={() => navTo(item.to)}
        key={index}
        className={style.headerBody__menuItem}
      >
        <span className={style.num}>0{index + 1}</span>
        {item.name}
        <span className={style.wrap}>
          <span className={style.num}>0{index + 1}</span>
          {item.name}
        </span>
      </div>
    ));
  };

  return (
    <header className={style.header}>
      <div className='container'>
        <div className={style.headerBody}>
          <Link to={'/'} className={style.headerBody__logo}>
            <img src={logoSvg} alt='' />
          </Link>

          {!isMobile && (
            <div className={style.headerBody__links}>{linkList()}</div>
          )}

          {isMobile && (
            <div
              onClick={() => setBurgerOpen(true)}
              className={style.headerBody__burger}
            >
              menu
            </div>
          )}

          {isMobile && (
            <div
              className={`${style.headerBody__menu} ${
                isBurgerOpen && style.open
              }`}
            >
              <div className='container'>
                <div className={style.headerBody__menuTop}>
                  <div className={style.headerBody__menuTitle}>
                    <span>menu</span>menu
                  </div>
                  <div
                    onClick={() => setBurgerOpen(false)}
                    className={style.headerBody__menuClose}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='26'
                      height='26'
                      viewBox='0 0 26 26'
                      fill='none'
                    >
                      <path
                        d='M9.02709 17.5563L13 13.5833L16.9729 17.5563L17.5563 16.9729L13.5833 13L17.5563 9.02709L16.9729 8.44374L13 12.4166L9.02709 8.44374L8.44374 9.02709L12.4166 13L8.44374 16.9729L9.02709 17.5563ZM13.0067 22.7187C11.6664 22.7187 10.4069 22.4625 9.22813 21.95C8.04937 21.4374 7.01909 20.7406 6.1373 19.8594C5.2555 18.9782 4.55904 17.9505 4.04793 16.7763C3.53681 15.602 3.28125 14.3455 3.28125 13.0067C3.28125 11.6623 3.53751 10.3972 4.05004 9.21147C4.56256 8.02576 5.25942 6.99427 6.1406 6.11698C7.0218 5.2397 8.04951 4.54776 9.22374 4.04115C10.398 3.53455 11.6545 3.28125 12.9933 3.28125C14.3377 3.28125 15.6029 3.53632 16.7887 4.04646C17.9745 4.5566 19.006 5.24895 19.8832 6.12351C20.7603 6.99805 21.4522 8.02591 21.9588 9.20708C22.4654 10.3882 22.7187 11.6503 22.7187 12.9933C22.7187 14.3336 22.4647 15.5931 21.9567 16.7719C21.4487 17.9506 20.7564 18.9809 19.8797 19.8627C19.003 20.7445 17.9741 21.441 16.7929 21.9521C15.6118 22.4632 14.3497 22.7187 13.0067 22.7187ZM12.9984 21.901C15.4668 21.901 17.5677 21.0339 19.301 19.2995C21.0344 17.5651 21.901 15.4658 21.901 13.0016C21.901 10.5332 21.0349 8.43228 19.3026 6.69895C17.5703 4.96562 15.47 4.09895 13.0016 4.09895C10.5373 4.09895 8.4375 4.96509 6.70209 6.69738C4.96666 8.42965 4.09895 10.53 4.09895 12.9984C4.09895 15.4627 4.96613 17.5625 6.70049 19.2979C8.43486 21.0333 10.5342 21.901 12.9984 21.901Z'
                        fill='#C4DBE0'
                      />
                    </svg>
                  </div>
                </div>
                <div className={style.headerBody__menuBody}>
                  {linkListMobile()}
                </div>
                <div className={style.headerBody__menuCr}>
                  algoquant 2023 <i>ⓒ</i>
                  <span>
                    algoquant 2023 <i>ⓒ</i>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
