import { Fade } from 'react-reveal';
import style from './Styles.module.scss';

const InfrastructureCard = ({ title, text }) => {
  return (
    <div className={style.card}>
      <span></span>
      <Fade up>
        <div className={style.card__title}>{title}</div>
      </Fade>
      <Fade up>
        <div className={style.card__text}>{text}</div>
      </Fade>
    </div>
  );
};

export default InfrastructureCard;
